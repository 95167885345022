import { Env } from 'src/typings';

export const environment: Partial<Env> = {
  production: true,
  envDomain: 'production',
  isProdDomain: true,

  logoutUrl: 'https://auth.netatmo.com/logout',
  appUrl: window.location.origin,

  apiUrl: "https://building-api.netatmo.com/api/",
  authUrl: "https://auth.netatmo.com/",
  cookiePrefix: "netatmocom",
  langsUrl: "/langs/",
  COOKIE_LOCALE_DOMAIN: '.netatmo.com',

  myAccountUrl: 'https://myconnectedaccount.netatmo.com',

  webPusherUrl: 'wss://app.netatmo.net/ws/',
};

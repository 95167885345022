import { createReducer, on } from '@ngrx/store';
import { Module } from '../modules/modules.interface';
import { addMeasures, aggregateMeasures30Minutes, extractModulesMeasures, extractRoomsMeasures, mergeMeasures, mergeModulesMeasures, mergeRoomsMeasures } from './measures';
import { LoadHomeMeasures, LoadHomeMeasuresSuccess } from './measures.actions';
import { EnumApiScales, EnumMeasureTypes, HomeMeasuresSets, MeasureTypes, Measures } from './measures.interface';
import { initialMeasuresState } from './measures.state';

export const MeasuresReducers = createReducer(
  initialMeasuresState,

  // LOAD MODULES MEASURES
  on(LoadHomeMeasuresSuccess, (state, action) => {
    const {home: {modules}, homeModules, scale} = action;

    const previousModulesMeasures = state.modules;
    const gatewayList = ['NLG', 'NLE', 'BNMH', 'BNCX', 'BNHY'];
    const gateway = homeModules?.find(m => gatewayList.includes(m.type));
    const modulesMeasures = extractModulesMeasures(scale, modules);

    modulesMeasures.forEach(moduleMeasures => {
      MeasureTypes.forEach(mType => {

        let measures: Measures = moduleMeasures[mType];

        switch (mType) {
          case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_NO_CONTRACT:
          case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_NO_CONTRACT:
          case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_BASE:
          case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_BASE:
          case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PEAK:
          case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_PEAK:
          case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_OFFPEAK:
          case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_OFFPEAK: {
            if (moduleMeasures.module.id !== gateway?.id) {
              measures = addMeasures(measures, moduleMeasures[NewToOldMeasureTypes[mType]] ?? []);
            }
            break;
          }
          // We want hourly measures for rain & therm data on lowest scale
          case EnumMeasureTypes.SUM_RAIN: {
            if (scale === EnumApiScales.FIVE_MINUTES) {
              measures = aggregateMeasures30Minutes(measures ?? []);
            }
            break;
          }
          case EnumMeasureTypes.SUM_BOILER_ON:
          case EnumMeasureTypes.SUM_BOILER_OFF:
          case EnumMeasureTypes.HEATING_POWER_REQUEST: {
            if (scale === EnumApiScales.FIVE_MINUTES) {
              measures = aggregateMeasures30Minutes(measures ?? [])
                .map(measure => ({...measure, y: measure.y / 3}));
            }
            break;
          }
        }

        // Remove values that are null
        measures = measures.filter(m => m.y !== null);
        console.log('measures', measures)
        moduleMeasures[mType] = measures;
      });
    });

    // SPECIAL CASE FOR BOILER_ON AND HEATING_POWER_REQUEST
    // it needs to be transformed into %
    if (action.home.modules.some(m => m.measureTypes.includes(EnumMeasureTypes.SUM_BOILER_ON))) {
      const modules = action.home.modules.filter(m => m.measureTypes.includes(EnumMeasureTypes.SUM_BOILER_ON));
      modules.forEach(module => {
        const moduleMeasures = modulesMeasures.find(m => m.module.id === module.id);
        moduleMeasures.sum_boiler_on = moduleMeasures.sum_boiler_on.map((v, i) => {
          return {
            x: v.x,
            // Value is boiler_on / (boiler_on + boiler_off) * 10000 / 100
            y: v.y / (v.y + moduleMeasures.sum_boiler_off[i].y) * 10000 / 100
          }
        });
      });
    }

    const newModulesMeasures = mergeModulesMeasures(previousModulesMeasures, modulesMeasures, scale);
    const moduleIds = modules.map(m => m.id);

    return {
      ...state,
        modules: [
        ...newModulesMeasures
      ],
      loadStatus: {
        ...state.loadStatus,
        modules: state.loadStatus.modules.filter(m => !moduleIds.includes(m.id) && m.scale !== scale),
      }
    };
  }),

  // LOAD ROOMS MEASURES
  on(LoadHomeMeasuresSuccess, (state, action) => {
    const {home: {rooms}, scale} = action;

    const previousRoomsMeasures = state.rooms;
    const roomsMeasures = extractRoomsMeasures(scale, rooms);

    roomsMeasures.forEach(roomMeasures => {
      MeasureTypes.forEach(mType => {

        let measures: Measures = roomMeasures[mType];

        switch (mType) {
          // We want hourly measures for therm data on lowest scale
          case EnumMeasureTypes.SUM_BOILER_ON:
          case EnumMeasureTypes.SUM_BOILER_OFF:
          case EnumMeasureTypes.HEATING_POWER_REQUEST: {
            if (scale === EnumApiScales.FIVE_MINUTES) {
              measures = aggregateMeasures30Minutes(measures ?? [])
                .map(measure => ({...measure, y: measure.y / 3}));
            }
            break;
          }
        }

        // Remove values that are null
        measures = measures.filter(m => m.y !== null);

        roomMeasures[mType] = measures;
      });
    });

    const newRoomsMeasures = mergeRoomsMeasures(previousRoomsMeasures, roomsMeasures, scale);
    const roomIds = rooms?.map(m => m.id);

    return {
      ...state,
      rooms: [
        ...newRoomsMeasures
      ],
      loadStatus: {
        ...state.loadStatus,
        rooms: state.loadStatus.rooms.filter(m => !roomIds.includes(m.id) && m.scale !== scale),
      }
    };

  }),

  // LOAD HOME MEASURES
  on(LoadHomeMeasuresSuccess, (state, action) => {
    const {home, scale, homeModules} = action;

    // If no home modules return state
    if (!homeModules) {
      return state;
    }

    const homeMeasures = state.homes[action.home.id] ??
      MeasureTypes.reduce((acc, mType) => {
        acc[mType] = {
          [EnumApiScales.FIVE_MINUTES]: [],
          [EnumApiScales.ONE_HOUR]: [],
          [EnumApiScales.THREE_HOURS]: [],
          [EnumApiScales.ONE_DAY]: [],
          [EnumApiScales.ONE_WEEK]: [],
          [EnumApiScales.ONE_MONTH]: [],
        };
        return acc;
      }, {
        home: {
          id: action.home.id,
        }
      } as HomeMeasuresSets);

    const getMeasures = (module: Module, measureType: EnumMeasureTypes) => state.modules.find(m => m.module.id === module.id)?.[measureType][scale] ?? [];
    MeasureTypes.forEach(mType => {

      const gateway = homeModules.find(m => m.type === 'NLG');
      const powerlines = homeModules.filter(m => ['NLPC', 'NLY'].includes(m.type) && m.measures_scope === 'device' && !m.id.includes('#'));
      const totalModule = homeModules.find(m => m.measured_elec_type === 'consumption' && m.measures_scope === 'total');
      const ecometer = homeModules.find(m => m.type === 'NLE' && m.id.includes('#5'));

      const bnxmPowerlines = homeModules.filter((m) => ['BNXM'].includes(m.type) && m.measured_elec_type === 'consumption' && m.measures_scope === 'device');
      const bnxmTotal = homeModules.find(m => ['BNXM'].includes(m.type) && m.measured_elec_type === 'consumption' && m.measures_scope === 'total');
      const bnxmPowerlinesProd = homeModules.filter((m) => ['BNXM'].includes(m.type) && m.measured_elec_type === 'production' && m.measures_scope === 'device');
      const bnxmTotalProduction = homeModules.find((m) =>['BNXM'].includes(m.type) && m.measured_elec_type === 'production' && m.measures_scope === 'total');
      const bnlcTotal = homeModules.find(m => ['BNLC'].includes(m.type) && m.measured_elec_type === 'consumption' && m.measures_scope === 'total');

      let measures: Measures = [];

      switch (mType) {
        case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_NO_CONTRACT:
        case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_NO_CONTRACT:
        case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_BASE:
        case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_BASE:
        case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PEAK:
        case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_PEAK:
        case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_OFFPEAK:
        case EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_OFFPEAK: {
          if (gateway) {
            const gatewayMeasuresOld = getMeasures(gateway, NewToOldMeasureTypes[mType]);
            const powerlineMeasuresOld = powerlines.map(module => getMeasures(module, NewToOldMeasureTypes[mType]));

            const gatewayMeasures = getMeasures(gateway, mType);

            measures = [gatewayMeasuresOld, ...powerlineMeasuresOld].reduce(addMeasures, []);

            if (totalModule) {
              const totalModuleMeasuresOld = getMeasures(totalModule, NewToOldMeasureTypes[mType]);
              measures = mergeMeasures(measures, totalModuleMeasuresOld);
            }

            measures = addMeasures(measures, gatewayMeasures);
          }

          if (bnxmTotal) {
            const bnxmTotalMeasures = getMeasures(bnxmTotal, mType);
            measures = mergeMeasures(measures, bnxmTotalMeasures);
          } else if (bnxmPowerlines.length > 0) {
            const bnxmPowerlinesMeasures = bnxmPowerlines.map(module => getMeasures(module, mType))
            measures = mergeMeasures(measures, bnxmPowerlinesMeasures.reduce(addMeasures));
          }

          if (bnlcTotal) {
            const bnlcTotalMeasuresOld = getMeasures(bnlcTotal, mType);
            measures = mergeMeasures(measures, bnlcTotalMeasuresOld);
          }

          if (ecometer) {
            const ecometerMeasures = getMeasures(ecometer, NewToOldMeasureTypes[mType]);
            measures = mergeMeasures(measures, ecometerMeasures);
          }
          break;
        }

        case EnumMeasureTypes.SUM_ENERGY_SELF_CONSUMPTION: {
          if (gateway) {
            measures = getMeasures(gateway, mType);
          }

          if (bnxmTotalProduction) {
            const bnxmTotalMeasures = getMeasures(bnxmTotalProduction, mType);
            measures = mergeMeasures(measures, bnxmTotalMeasures);
          } else if (bnxmPowerlinesProd.length > 0) {
            const bnxmPowerlinesMeasuresProd = bnxmPowerlinesProd.map(module => getMeasures(module, mType))
            measures = mergeMeasures(measures, bnxmPowerlinesMeasuresProd.reduce(addMeasures));
          }

          break;
        }

        default: {
          if (gateway) {
            measures = getMeasures(gateway, mType);
          }
          break;
        }
      }

      homeMeasures[mType][scale] = measures;
    });

    return {
      ...state,
      homes: {
        ...state.homes,
        [action.home.id]: {
          ...homeMeasures
        },
      },
      loadStatus: {
        ...state.loadStatus,
        homes: state.loadStatus.homes.filter(h => h.id !== action.home.id && h.scale !== scale),
      }
    };
  }),

  on(LoadHomeMeasures, (state, {home: {id, modules, rooms}, scale}) => {
    return {
      ...state,
      loadStatus: {
        ...state.loadStatus,
        homes: [...state.loadStatus.homes, {id: id, scale}],
        modules: [...state.loadStatus.modules, ...modules.map(m => ({id: m.id, scale}))],
        rooms: [...state.loadStatus.rooms, ...rooms.map(m => ({id: m.id, scale}))],
      }
    };
  }),
);

const NewToOldMeasureTypes = {
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_NO_CONTRACT]: EnumMeasureTypes.SUM_ENERGY_ELEC_NO_CONTRACT,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_NO_CONTRACT]: EnumMeasureTypes.SUM_ENERGY_PRICE_NO_CONTRACT,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_BASE]: EnumMeasureTypes.SUM_ENERGY_ELEC_BASE,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_BASE]: EnumMeasureTypes.SUM_ENERGY_PRICE_BASE,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PEAK]: EnumMeasureTypes.SUM_ENERGY_ELEC_PEAK,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_PEAK]: EnumMeasureTypes.SUM_ENERGY_PRICE_PEAK,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_OFFPEAK]: EnumMeasureTypes.SUM_ENERGY_ELEC_OFFPEAK,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_OFFPEAK]: EnumMeasureTypes.SUM_ENERGY_PRICE_OFFPEAK,
};

import * as moment from 'moment-timezone';
import {
  EnumApiScales, EnumMeasureTypes, EnumScales, ExtendedMeasureTypes, Measures, MeasuresSet, MeasuresSetsScaled, MeasureTypes,
  ModuleMeasuresSets, ModuleMeasuresSetsScaled,
  RawMeasuresBatch, RawModuleMeasures, RoomMeasuresSets, RoomMeasuresSetsScaled
} from './measures.interface';

/**
 * Merges arr1 and arr2 and overrides duplicates with arr2 newest values
 * @param arr1 array to merge
 * @param arr2 array to merge
 */
export const mergeMeasures = (arr1: Measures, arr2: Measures): Measures => {
  // Create a new array containing both arrays
  const newArr = [...arr1, ...arr2];

  // Sort measures by dates
  newArr.sort((a, b) => {
    if (a.x < b.x) {
      return -1;
    } else if (a.x > b.x) {
      return 1;
    } else {
      return 0;
    }
  });

  // Delete duplicates
  return newArr.reduce((acc, curr) => {
    // at the begining do nothing and just return an array containing the first measure
    if (acc.length === 0) {
      acc = [curr];
    }
    // If the current measures is the same as the previous one, replace the last one with the new one
    else if (acc[acc.length - 1].x == curr.x) {
      acc[acc.length - 1] = curr;
    }
    // If the measures is a new one just push it in the array
    else {
      acc.push(curr);
      return acc;
    }

    return acc;
  }, [] as Measures);
};

/**
 * Merges arr1 and arr2 sum y values with same x value
 * @param arr1 array to merge
 * @param arr2 array to merge
 */
export const addMeasures = (arr1: Measures, arr2: Measures): Measures => {
  // Create a new array containing both arrays
  const newArr = [...arr1, ...arr2];

  // Sort measures by dates
  newArr.sort((a, b) => {
    if (a.x < b.x) {
      return -1;
    } else if (a.x > b.x) {
      return 1;
    } else {
      return 0;
    }
  });

  // Sum duplicates
  return newArr.reduce((acc, curr) => {
    // at the begining do nothing and just return an array containing the first measure
    if (acc.length === 0) {
      acc = [curr];
    }
    // If the current measures is the same as the previous one, add y values together
    else if (acc[acc.length - 1].x == curr.x) {
      acc[acc.length - 1] = {...curr, y: acc[acc.length - 1].y + curr.y };
    }
    // If the measures is a new one just push it in the array
    else {
      acc.push(curr);
      return acc;
    }

    return acc;
  }, [] as Measures);
};

/**
 * Returns a new array composed of contigous measures from batch1 and batch2
 */
export function joinBatches(batch1: Measures, batch2: Measures, scale: EnumApiScales): Measures {
    return [...batch1, ...batch2];
}

export function generateBatch(values: number[], begTime: number, stepTime: number, scale: EnumApiScales)
: Measures {
  if (values.length === 0) {
      return [];
  }

  const batch = values.map((v, i) => {
    return ({
      x: begTime + stepTime * i,
      y: v,
    })
  });

  return batch;
}

export function extractRawMeasuresBatches(indexes: number[], rawMeasuresBatches: RawMeasuresBatch[]) {
  return rawMeasuresBatches.map(rawBatch => ({
    begTime: rawBatch.beg_time * 1000,
    stepTime: isNaN(rawBatch.step_time) ? 0 : rawBatch.step_time * 1000,
    values: rawBatch.value
      .map(v => (indexes as number[])
      .reduce((acc, curr) => {
        if (v[curr] === null) {
          return acc;
        }
        else {
          return acc + v[curr]
        }
      }, null))
  }));
}

export function combineMeasuresOfSameDate() {
  return (batches: Measures) => batches.reduce((acc, curr, i) => {
    if (acc[acc.length - 1] && curr.x === acc[acc.length - 1]?.x) {
      acc[acc.length - 1].y += curr.y;
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);
}

function extractSingleModuleMeasures(scale: EnumApiScales, module: RawModuleMeasures): ModuleMeasuresSetsScaled {
  const moduleMeasures = extractMeasures(scale, module);

  return {
    module: {
      id: module.id,
    },
    ...moduleMeasures
  }
}

function extractSingleRoomMeasures(scale: EnumApiScales, room: RawModuleMeasures): RoomMeasuresSetsScaled {
  const roomMeasures = extractMeasures(scale, room);

  return {
    room: {
      id: room.id,
    },
    ...roomMeasures
  }
}

function extractMeasures(scale: EnumApiScales, {measures, measureTypes}: RawModuleMeasures): MeasuresSetsScaled {
  const MeasureTypesIndexesEntries = measureTypes
    .map((mType, index) => {
      if (ExtendedMeasureTypes[mType]) {
        return [mType, ExtendedMeasureTypes[mType].map(m => measureTypes.indexOf(m))];
      } else {
        return [mType, [index]];
      }
    }) as [EnumMeasureTypes, number[]][];

  const rawBatches = measures || [];

  let modulesMeasures = MeasureTypesIndexesEntries
    // Extract raw Batches by types
    .map(([, measureTypes]) => extractRawMeasuresBatches(measureTypes, rawBatches))
    // Transform raw batches to batches
    .map(rawMeasuresBatches => rawMeasuresBatches.map(rawMeasuresBatch => generateBatch(rawMeasuresBatch.values, rawMeasuresBatch.begTime, rawMeasuresBatch.stepTime, scale)))
    // Join batches
    .map(measuresBatches => measuresBatches.reduce((acc, curr) => joinBatches(acc, curr, scale), []))
    // SOmetimes we have 2 measures with same date so we need to combine them together (ading together the y values)
    .map(combineMeasuresOfSameDate())
    // Transform to ModuleMeasuresSetsScaled
    .reduce((acc, curr, index) => {
      acc[MeasureTypesIndexesEntries[index][0]] = curr;
      return acc;
    }, {} as Record<EnumMeasureTypes, Measures>);

  return {
    // initialize measure to empty arrays
    ...MeasureTypes.reduce((acc, mType) => {
      acc[mType] = [];
      return acc;
    }, {} as MeasuresSetsScaled),
    ...modulesMeasures,
  };
}

export function extractModulesMeasures(scale: EnumApiScales, modules: RawModuleMeasures[] = []): ModuleMeasuresSetsScaled[] {
  return modules.map(module => extractSingleModuleMeasures(scale, module));
}

export function extractRoomsMeasures(scale: EnumApiScales, rooms: RawModuleMeasures[] = []): RoomMeasuresSetsScaled[] {
  return rooms.map(room => extractSingleRoomMeasures(scale, room));
}

export function mergeHomeMeasures(previousMeasures: MeasuresSetsScaled, modulesMeasures: ModuleMeasuresSetsScaled[], id: string) {
  const measures = modulesMeasures.find(m => m.module.id === id);
  if (measures) {
    MeasureTypes.forEach(mType => {
      const mergedHomeMeasures =  mergeMeasures(previousMeasures[mType], measures[mType])
      if (mergedHomeMeasures.length > 0) {
        previousMeasures[mType] = mergedHomeMeasures;
      }
    });
  }
  return previousMeasures
}

export function addModuleMeasures(previousMeasures: MeasuresSetsScaled, modulesMeasures: ModuleMeasuresSetsScaled[], id: string) {
  const measures = modulesMeasures.find(m => m.module.id === id);
  if (measures) {
    MeasureTypes.forEach(mType => addMeasures(previousMeasures[mType], measures[mType]));
  }
}

export function mergeModulesMeasures(
  previousModulesMeasures: ModuleMeasuresSets[],
  newModulesMeasures: ModuleMeasuresSetsScaled[],
  apiScale: EnumApiScales,
) {
  previousModulesMeasures = previousModulesMeasures.map(moduleMeasures => {
    const newModuleMeasures = newModulesMeasures.find(m => m.module.id === moduleMeasures.module.id);
    if (newModuleMeasures) {
      MeasureTypes
        .filter(mType => newModuleMeasures[mType])
        .forEach(mType => {
          moduleMeasures[mType][apiScale] = mergeMeasures(moduleMeasures[mType][apiScale], newModuleMeasures[mType])
        });
    }

    return moduleMeasures;
  });

  const modulesMeasures = newModulesMeasures
    .filter(moduleMeasures => !previousModulesMeasures.find(m => m.module.id === moduleMeasures.module.id))
    .map(moduleMeasures => {
      return {
          module: moduleMeasures.module,
          ...MeasureTypes.reduce((acc, mType) => {
            acc[mType] = {
              [EnumApiScales.FIVE_MINUTES]: [],
              [EnumApiScales.ONE_HOUR]: [],
              [EnumApiScales.THREE_HOURS]: [],
              [EnumApiScales.ONE_DAY]: [],
              [EnumApiScales.ONE_WEEK]: [],
              [EnumApiScales.ONE_MONTH]: [],
              [apiScale]: moduleMeasures[mType]
            };
            return acc;
          }, {} as Record<EnumMeasureTypes,  MeasuresSet>),
      };
    });

  return [
    ...previousModulesMeasures,
    ...modulesMeasures
  ];
}

export function mergeRoomsMeasures(
  previousRoomsMeasures: RoomMeasuresSets[],
  newRoomsMeasures: RoomMeasuresSetsScaled[],
  apiScale: EnumApiScales,
) {
  previousRoomsMeasures = previousRoomsMeasures.map(roomMeasures => {
    const newRoomMeasures = newRoomsMeasures.find(m => m.room.id === roomMeasures.room.id);
    if (newRoomMeasures) {
      MeasureTypes
        .filter(mType => newRoomMeasures[mType])
        .forEach(mType => {
          roomMeasures[mType][apiScale] = mergeMeasures(roomMeasures[mType][apiScale], newRoomMeasures[mType])
        });
    }

    return roomMeasures;
  });

  const roomsMeasures = newRoomsMeasures
    .filter(roomMeasures => !previousRoomsMeasures.find(m => m.room.id === roomMeasures.room.id))
    .map(roomMeasures => {
      return {
          room: roomMeasures.room,
          ...MeasureTypes.reduce((acc, mType) => {
            acc[mType] = {
              [EnumApiScales.FIVE_MINUTES]: [],
              [EnumApiScales.ONE_HOUR]: [],
              [EnumApiScales.THREE_HOURS]: [],
              [EnumApiScales.ONE_DAY]: [],
              [EnumApiScales.ONE_WEEK]: [],
              [EnumApiScales.ONE_MONTH]: [],
              [apiScale]: roomMeasures[mType]
            };
            return acc;
          }, {} as Record<EnumMeasureTypes,  MeasuresSet>),
      };
    });

  return [
    ...previousRoomsMeasures,
    ...roomsMeasures
  ];
}

/**
 * Transforms the date to the start of the given apiscale
 */
export function startOfApiScale(date: moment.Moment, scale: EnumApiScales) {
  switch (scale) {
    case EnumApiScales.FIVE_MINUTES:
      return date.startOf('day').startOf('minute');
    case EnumApiScales.THREE_HOURS:
      return date.startOf('isoWeek').startOf('hour');
    case EnumApiScales.ONE_DAY:
      return date.startOf('month').startOf('day');
    case EnumApiScales.ONE_WEEK:
      return date.startOf('year').startOf('isoWeek');
    default:
      throw new Error(`scale ${scale} not implemented`)
  }
}

/**
 * Transforms the date to the end of the given apiscale
 */
export function endOfApiScale(date: moment.Moment, scale: EnumApiScales) {
  switch (scale) {
    case EnumApiScales.FIVE_MINUTES:
      return date.endOf('day');
    case EnumApiScales.THREE_HOURS:
      return date.endOf('isoWeek');
    case EnumApiScales.ONE_DAY:
      return date.endOf('month');
    case EnumApiScales.ONE_WEEK:
      return date.endOf('year');
    default:
      throw new Error(`scale ${scale} not implemented`);
  }
}

export function startOfScale(date: moment.Moment, scale: EnumScales) {
  switch (scale) {
    case EnumScales.DAY:
    case EnumScales.DAY_HOURLY:
      return date.startOf('day').startOf('minute');
    case EnumScales.WEEK:
    case EnumScales.WEEK_DAILY:
      return date.startOf('isoWeek').startOf('hour');
    case EnumScales.MONTH_DAILY:
      return date.startOf('month').startOf('day');
    case EnumScales.MONTH_WEEKLY:
      return date.startOf('month').startOf('isoWeek');
    case EnumScales.YEAR:
      return date.startOf('year').startOf('isoWeek');
    case EnumScales.YEAR_MONTHLY:
      return date.startOf('year').startOf('month');
    default:
      throw new Error(`scale ${scale} not implemented`)
  }
}

export function endOfScale(date: moment.Moment, scale: EnumScales) {
  switch (scale) {
    case EnumScales.DAY:
    case EnumScales.DAY_HOURLY:
      return date.endOf('day');
    case EnumScales.WEEK:
    case EnumScales.WEEK_DAILY:
      return date.endOf('isoWeek');
    case EnumScales.MONTH_DAILY:
      return date.endOf('month');
    case EnumScales.MONTH_WEEKLY:
      return date.endOf('month').endOf('isoWeek');
    case EnumScales.YEAR:
      return date.endOf('year').endOf('isoWeek');
    case EnumScales.YEAR_MONTHLY:
      return date.endOf('year');
    default:
      throw new Error(`scale ${scale} not implemented`)
  }
}

/**
 * Combines all measures that are at the 30minutes "slot" in same hour in the same day in the same year
 */
export function aggregateMeasures30Minutes(measures: Measures) {

  return measures.reduce((acc, curr) => {
    const currDate = moment(curr.x);

    if (acc.length === 0) {
      acc.push({x: currDate.startOf('hour').valueOf(), y: curr.y});
      return acc;
    }

    const last = acc[acc.length - 1];
    const lastDate = moment(last.x);
    const lastDateMinutes = parseInt(lastDate.format('mm'), 10);
    const currDateMinutes = parseInt(currDate.format('mm'), 10);

    if (lastDate.format('HH DDD YYYY') === currDate.format('HH DDD YYYY') && Math.floor(currDateMinutes / 30) === Math.floor(lastDateMinutes / 30)) {
      acc[acc.length - 1] = {
        ...last,
        y: last.y + curr.y,
      };
    }
    else {
      if (currDateMinutes < 30) {
        acc.push({x: currDate.startOf('hour').valueOf(), y: curr.y});
      } else {
        acc.push({x: currDate.startOf('hour').add(30, 'minutes').valueOf(), y: curr.y});
      }
    }

    return acc;
  }, [] as Measures);
}

